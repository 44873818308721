import React from 'react';
import Image from '../../common/components/Image';
import PageMessageWrapper from './PageMessage.style';

export default function PageMessage({ title, imageUrl }) {
    return (
        <PageMessageWrapper>
            <Image src={imageUrl} alt={title} />
            <h1>{title}</h1>
        </PageMessageWrapper>
    );
}
