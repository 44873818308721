import React from 'react';
import { __ } from '../common/utils/LanguageHelper';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from '../common/theme/saas';
import Sticky from 'react-stickynode';
import { GlobalStyle, ContentWrapper } from '../containers/saas.style';
import { DrawerProvider } from '../common/contexts/DrawerContext';
import Navbar from '../containers/Navbar';
import SEO from '../components/seo';
import { ResetCSS } from '../common/assets/css/style';
import Footer from '../containers/Footer';
import PageMessage from '../containers/PageMessage';
import BackgroundShape from '../containers/BackgroundShape';

import ComingSoon from '../common/assets/image/wating_image.png';

export default () => {
    return (
        <ThemeProvider theme={saasTheme}>
            <SEO title={__('أخبار المنصة', 'Awasul News')} />
            <ResetCSS />
            <GlobalStyle />
            <ContentWrapper>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                    <DrawerProvider>
                        <Navbar />
                    </DrawerProvider>
                </Sticky>
                <BackgroundShape
                    position="right"
                    className="background-shape__clients"
                />
                <PageMessage
                    title="جاري العمل على ما هو جديد وسنبقيك على اطلاع دائما, ترقبنا"
                    imageUrl={ComingSoon}
                />
                <Footer />
            </ContentWrapper>
        </ThemeProvider>
    );
};
