import styled from 'styled-components';

const PageMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;

    img {
        max-width: 750px;

        @media (max-width: 1186px) {
            max-width: 500px;
        }

        @media (max-width: 562px) {
            max-width: 400px;
        }
    }

    h1 {
        margin: 0;
        text-align: center;
        padding: 0 25px;

        @media (max-width: 1186px) {
            font-size: 28px;
        }

        @media (max-width: 562px) {
            font-size: 22px;
        }
    }
`;

export default PageMessageWrapper;
